import { Route, Routes } from "react-router-dom";
import MainPage from "./components/mainPage/MainPage";
import Header from "./components/navBar/Header";
import './App.css';
import LogoForm from "./components/dashboard/LogoForm";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Pay from "./components/pay/Pay";
import { useState } from "react";

function App() {
  
  const [profile, setProfile] = useState(null);
  const [nameValue, setNameValue] = useState("");
  const [objectValue, setObjectValue] = useState("");
  const [styleValue, setStyleValue] = useState("");
  const [policyStatus, setPolicyStatus] = useState(false);

  return (
    <GoogleOAuthProvider clientId="650142851440-lq5jnc2e7776um1o7viuao0bgvv567mo.apps.googleusercontent.com">
        <Header profile={profile} setProfile={setProfile} />
        <Routes>
          <Route index element={<MainPage />} />
          <Route path='/logo-form' element={<LogoForm 
          profile={profile} setProfile={setProfile} 
          nameValue={nameValue} setNameValue={setNameValue} 
          objectValue={objectValue} setObjectValue={setObjectValue} 
          styleValue={styleValue} setStyleValue={setStyleValue} 
          policyStatus={policyStatus} setPolicyStatus={setPolicyStatus} 
          />} />
          <Route path='/pay-embl' element={<Pay profile={profile} />} />
        </Routes>
    </GoogleOAuthProvider>
  );
}

export default App;