import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { aiLogoApi } from './services/aiLogo'

export const store = configureStore({
    reducer: {
        [aiLogoApi.reducerPath]: aiLogoApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            aiLogoApi.middleware,
        ),
})

setupListeners(store.dispatch)