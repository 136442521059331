import { BiCoin, BiCoinStack } from 'react-icons/bi';
import { GiCoins } from 'react-icons/gi';

const Pay = (profile) => {
  return (
    <div className="conteiner">
        <div className="conteiner-block">
            <div className="pay-block">
                <div className="box">
                    <div className="box__head">
                        <BiCoin />
                    </div>
                    <div className="box__content">
                        <h2>Малий Пак</h2>
                        <p>9 Embl / 30 грн</p>
                    </div>
                    <div className="box__social">
                        {profile?.profile?.picture &&
                            <button className="form-button">Купити</button>
                        }
                    </div>
                </div>
                
                <div className="box">
                    <div className="box__head">
                        <BiCoinStack />
                    </div>
                    <div className="box__content">
                        <h2>Середній Пак</h2>
                        <p>90 Embl / 270 грн</p>
                    </div>
                    <div className="box__social">
                        {profile?.profile?.picture &&
                            <button className="form-button">Купити</button>
                        }
                    </div>
                </div>
                
                <div className="box">
                    <div className="box__head">
                        <GiCoins />
                    </div>
                    <div className="box__content">
                        <h2>Великий Пак</h2>
                        <p>450 Embl / 1200 грн</p>
                    </div>
                    <div className="box__social">
                        {profile?.profile?.picture &&
                            <button className="form-button">Купити</button>
                        }
                    </div>
                </div>
            </div>
            <div className='pay-text'>На одну генерацію яка складається з 9 картинов витрачається 9 Embl.</div>
        </div>
    </div>
  );
};

export default Pay;