import { Link } from "react-router-dom"

const MainPage = () => {
    return (
        <div className="conteiner">
            <div className="conteiner-block">
                <h2 className="main-title">
                    Спробуй Штучний Інтелект для створення Логотипу!
                </h2>
                <h4 className="main-list">Створи логоти менше ніж за 2 хвилини та завантажуй його в зручному для себе форматі.</h4>
                <h4 className="main-list">Вибери стиль та обєкти які хочеш бачити на логотипі а все інше зробить Штучний Інтелект.</h4>
                <h4 className="main-list">Кожен логотип унікальний.</h4>
                <div className="button-block">
                    <Link className="get-start" to={"/logo-form"}>
                        Почати Зараз
                    </Link>
                </div>
            </div>
        </div>
    );
}
  
export default MainPage;
  