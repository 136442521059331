import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const aiLogoApi = createApi({
    reducerPath: 'aiLogoApi',
    baseQuery: fetchBaseQuery({ 
        baseUrl: 'https://emblemize-backend-pjabuzl6rq-ez.a.run.app/',
     }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (token) => {
                if (!token) {
                    return null;
                }
                return {
                    url: `users/me`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    })

})

export const { 
    useGetUserQuery,
} = aiLogoApi