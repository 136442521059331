import { useEffect, useState } from "react";
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleLogin } from "@react-oauth/google";
import { useGetUserQuery } from "../../redux/services/aiLogo";

function Checkbox({ id, checked, onChange }) {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label id="policyStatus" htmlFor={id}>Конфіденційність</label>
    </div>
  );
}

const LogoForm = ({profile, setProfile, nameValue, setNameValue, objectValue, setObjectValue, styleValue, setStyleValue, policyStatus, setPolicyStatus}) => {
    const [dropdown, setDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [checked, setChecked] = useState(false);
    const [user, setUser] = useState();
    const userInfo = useGetUserQuery(user);

    useEffect(() => {
        if (user) {
            setProfile(userInfo.data);
        }
    }, [userInfo.isSuccess, user]);

    const handleChange = () => {
        setChecked(!checked);
        setPolicyStatus(!checked)
        document.getElementById("policyStatus").classList.remove("false-check")
    };

    const handleClickOutside = (event) => {
    const dropdownEl = document.querySelector('.dropdown');
    if (dropdownEl && !dropdownEl.contains(event.target)) {
        setDropdown(false);
    }
    }

    const handleOptionClick = (option, optionUA) => {
        setSelectedOption(optionUA);
        setDropdown(false);
        setStyleValue(option)
        document.getElementById("styleInput").classList.remove("false-input")
    }

    const handleCreateLogoClick = () => {
        console.log(nameValue, objectValue, styleValue, policyStatus)
        if(nameValue === "") {
            document.getElementById("nameInput").classList.add("false-input")
        }
        if(objectValue === "") {
            document.getElementById("objectInput").classList.add("false-input")
        }
        if(styleValue === "") {
            document.getElementById("styleInput").classList.add("false-input")
        }
        if(policyStatus === false) {
            document.getElementById("policyStatus").classList.add("false-check")
        }
    }

    useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const responseMessage = (response) => {
        setUser(response.credential);
        Cookies.set('google_users', response.credential);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    return (
        <div className="conteiner">
            <div className="conteiner-block">
                <div className="dashboard">
                    <div className="form-logo">
                        <h3>Форма для створення логотипу</h3>
                        <div className="input-block">
                            <span>Назва вашої справи<span className="necessarily">*</span></span>
                            <input className="input" type="text" id="nameInput" value={nameValue} onChange={(event)=>{
                                setNameValue(event.target.value)
                                document.getElementById("nameInput").classList.remove("false-input")
                                }} />
                        </div>
                        <div className="input-block">
                            <span>Основний обєкт логотипу<span className="necessarily">*</span></span>
                            <input className="input" type="text" id="objectInput" value={objectValue} onChange={(event)=>{
                                setObjectValue(event.target.value)
                                document.getElementById("objectInput").classList.remove("false-input")
                                }} />
                        </div>
                        
                        <div className="input-block">
                            <span>Виберіть стиль в якому робити логотип<span className="necessarily">*</span></span>
                            <div className={dropdown ? "form-dropdown form-dropdown-active" : "form-dropdown"} id="styleInput" onClick={() => {setDropdown(!dropdown)}}>
                            <span>{selectedOption || 'Виберіть стиль'}</span>
                            <IoIosArrowDown />
                            </div>
                            {dropdown &&
                            <div className='form-dropdown-content'>
                                <button className='' onClick={() => handleOptionClick('Minimalism', 'Мінімалізм')}>Мінімалізм</button>
                                <button className='' onClick={() => handleOptionClick('Surrealism', 'Сюрреалізм')}>Сюрреалізм</button>
                                <button className='' onClick={() => handleOptionClick('Modern', 'Модерн')}>Модерн</button>
                            </div>
                            }
                        </div>
                        <Checkbox id="checkbox-1" checked={checked} onChange={handleChange} />
                        {profile ?
                            <Link to={nameValue !== "" && objectValue !== "" && styleValue !== "" && policyStatus && profile?.picture && '/pay-embl'} onClick={handleCreateLogoClick} className="form-button">
                                Створити Логотип
                            </Link>
                            :
                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default LogoForm;
  