import { HiMenu } from 'react-icons/hi';
import { FcGoogle } from 'react-icons/fc';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { useGetUserQuery } from '../../redux/services/aiLogo';
import Cookies from 'js-cookie';

const Header = ({profile, setProfile}) => {
  const [dropdown, setDropdown] = useState(false);

  const handleClickOutside = (event) => {
    const dropdownEl = document.querySelector('.user-block');
    if (dropdownEl && !dropdownEl.contains(event.target)) {
      setDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const [user, setUser] = useState();
  const userInfo = useGetUserQuery(user);

  useEffect(() => {
    if (user) {
        setProfile(userInfo.data);
    }
  }, [userInfo.isSuccess, user]);

  useEffect(() => {
    const userProfile = Cookies.get("google_users");
    if (userProfile) {
        setUser(userProfile);
    }
  }, []);

  const logOut = () => {
    googleLogout();
    setProfile(null);
    setUser(false)
    Cookies.remove('google_users');
  };

  const responseMessage = (response) => {
    setUser(response.credential);
    Cookies.set('google_users', response.credential);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <div className="header">
      <Link className="logo-block" to={"/"}>
        <h3 className="logo-text">Emblemize</h3>
      </Link>
      {profile ? (
        <div className="user-block" onClick={() => { setDropdown(!dropdown) }}>
          <HiMenu />
          <div className='user'>
            <div className='user-circle'>
              <img src={profile.picture} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="user-block google-block">
          <div className="d-none-google">
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
          </div>
          <div className='user google'>
            <div className='user-circle user-circle-google'>
              <FcGoogle />
            </div>
          </div>
        </div>
      )}

      {dropdown &&
        <div className='user-dropdown'>
          <button className='user-dropdown-button'>Моя галерея</button>
          <button className='user-dropdown-button' onClick={logOut}>Вийти</button>
        </div>
      }
    </div>
  );
}

export default Header;